import React from 'react';

import icon2 from '../../assets/icons/icon-2.svg';
import icon3 from '../../assets/icons/icon-3.svg';
import Screen from '../../components/screen/screen';
import type { IRegistryScreenData } from '../../types/ScreenData';

const data: IRegistryScreenData = {
    hero: {
        title: 'HMI<br/>software',
        text : 'Сервисы для&nbsp;комфортного и&nbsp;безопасного вождения: навигация, музыка, заправка автомобиля и&nbsp;голосовой ассистент.'
    },
    sections: [
        {
            title  : 'Возможности системы',
            icon   : icon2,
            content: `
      <ul class="screen__list">
        <li><b>Навигация </b><span>Понятный интерфейс для&nbsp;точного и&nbsp;быстрого реагирования</span></li>
        <li><b>Музыка </b><span>Доступ к&nbsp;любимым плейлистам и&nbsp;радиостанциям</span></li>
        <li><b>Голосовой ассистент и&nbsp;звонки </b><span>Безопасное управление автомобилем</span></li>
        <li><b>Онлайн-заправка </b><span>Комфортный и&nbsp;быстрый процесс заправки автомобиля</span></li>
      </ul>
      `
        },
        {
            title  : 'Стоимость доступа к&nbsp;сервисам',
            icon   : icon2,
            content: 'рассчитывается индивидуально в&nbsp;зависимости от&nbsp;функционала, объёмов и&nbsp;других условий. Узнать подробности можно по&nbsp;адресу электронной почты <a href="mailto:partners@autotech.team">partners@autotech.team</a>'
        },
        {
            title  : 'Документация',
            icon   : icon3,
            content: `
      <ul>
        <li><a href="/registry/opisanie-po.pdf" target="_blank">Описание ПО</a></li>
        <li><a href="/registry/instrukciya-playauto.pdf" target="_blank">Руководство по установке и эксплуатации</a></li>
      </ul>
      `
        }
    ]
};

const HMISoftwareComponent = () => {
    return <Screen data={data} />;
};

export default HMISoftwareComponent;
