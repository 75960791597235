import React from 'react';

import HMISoftwareComponent from '@/components/registry/screens/HMISoftware/HMISoftware';

const HMISoftware = () => {
    return (
        <HMISoftwareComponent />
    );
};

export default HMISoftware;
